<template>
<v-container fluid class="pa-0">
<v-card>
    <v-toolbar class="elevation-1" color="primary" height="48px">
        <v-toolbar-title >Associer la liste</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs12 class="pa-2 purple--text">
            Lisaison avec la liste :
        </v-flex>
        <v-flex xs12 class="pr-2 pl-2">
            <v-select color="purple"
                :items="lists" item-value="lst_id" item-text="lst_label"
                v-model="listLink.lst_id_link" label="Liste" >
            </v-select>
        </v-flex>
        <v-flex xs12 class="pa-2">
            <v-file-input @change="getFileDocument" ref="listfile" v-model="listLink.obj_file" show-size label="Fichier"></v-file-input>
        </v-flex>
    </v-layout>
    </v-card-text>
    <v-card-actions>
        <v-btn outlined color="orange" title="Télécharger la liste"
            :loading="saveProgress" @click.native="downloadLists()" :disabled="saveProgress">
            <v-icon dark>mdi-cloud</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn 
            color="success" :loading="saveProgress" @click.native="syncroItems()" :disabled="saveProgress || !listLink.lst_id_link">Syncroniser</v-btn>
        <v-btn color="primmary" @click.native="switchModal('lsdLinkModal', 0, false)">Retour</v-btn>
    </v-card-actions>
</v-card> 
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'listlink_edit',
    props: ['toast', 'lst_id', 'switchModal'],
    data: function () {
      return {
        saveProgress: false,         // Enregistrement en cours...
        locDateFormat: dateLocale, // Format de date
        lists : [],
        listsData: {},
        listLink:  {
            lst_id: 0,
            lst_id_link: 0,
            lst_filename: '',
            obj_file: null,
            lst_sta_id: 'GEN01'
        }
      }
    },
    mounted: function (){
        let lists      = this.getLists()
        this.lists     = lists.filter(word => word.lst_id !== this.lst_id)
        this.listLink.lst_id  = this.lst_id
    },

    methods: {
        getItem(){

        },
        syncroItems(){
            this.saveProgress = true
            var formFile = {}
            formFile = new FormData()
            for (let prop in this.listLink) {
                formFile.append(prop, this.listLink[prop])
            }
            if( this.listLink.obj_file ) {
                formFile.append('lists_file', this.listLink.obj_file, this.listLink.obj_file.name)
            }
            this.$http.post( '/listtemplates/assoc/', formFile )
            .then( () => {
                this.saveProgress = false
                this.toast({ text: 'Synchronisation terminée !' })
                this.switchModal('lsdLinkModal', 0, true)
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de association !', error: error })
                this.saveProgress = false
            })
        },
        downloadLists(){
            this.saveProgress = true
            this.$http.customRequest({ method: 'get', url: '/listtemplates/xls/' + this.lst_id, responseType: 'blob' } )
            .then( (response) => {
                this.$download(response.data, "list.xlsx", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet")
                this.saveProgress = false
            }).catch( (error) => {
                this.toast({ text: 'Erreur lors de l\'extraction !', error: error })
                this.saveProgress = false
            })
        },
        getFileDocument(file){
            this.listLink.lst_filename = file.name
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getLists:      cacheGetters.getLists,
        getListsDataByLstId: cacheGetters.getListsDataByLstId,
    },
    components: { }
}
</script>
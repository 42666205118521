/* *****************************
    Objet : Project
******************************** */
import { cacheGetters } from "../store/cache.module"

export const proObj = {
    data: function () {
        return {
            objProject: {
                pro_id: 0, pro_sta_id: 'GEN01', pro_typ_id_projection: 'PRJ01', pro_label: '', pro_comment: null, pro_sketchfab_id: null, 
                pro_projection: '', pro_blk_id: null, pro_lat: null, pro_lng: null, pro_doc_id_logo: null,
                pro_date_cal: null, pro_date_cal_end: null, pro_obt_id_phyto: null,
                pro_address: '', pro_zip: '', pro_town: '', pro_country: '', pro_custom_filter: []
            },
            objProjectClone: {},
            listProject: [],
            metaProject: {},
        }
    },
    mounted: function (){
        this.objProjectClone = JSON.stringify( this.objProject )
    },
    methods: {
        getProjectFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        // Get Project
        getProjectShow(){
            return new Promise( (resolve, reject) => {
                this.$http.get( '/projects/' + this.objProject.pro_id).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( this.objProject[prop] !== undefined && Object.prototype.toString.call( response.data.data[prop] ) === '[object Array]' ) {
                            response.data.data[prop].forEach(element => {
                                this.objProject[prop].push(element)
                            });
                        } else if( this.objProject[prop] !== undefined ){
                            this.objProject[prop] = response.data.data[prop] 
                        }
                    }
                    this.objProject.pro_custom_filter = (!this.objProject.pro_custom_filter) ? [] : this.objProject.pro_custom_filter
                    //if( Object.prototype.toString.call( this.objProject.pro_custom_filter ) === '[object Array]' ) {
                    //    this.objProject.pro_custom_filter = []
                    //}
                    this.objProject.pro_date_cal   = ( this.objProject.pro_date_cal == '0000-00-00' ) ? null : this.objProject.pro_date_cal
                    this.objProject.pro_date_cal_end = ( this.objProject.pro_date_cal_end == '0000-00-00' ) ? null : this.objProject.pro_date_cal_end
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getProjectIndex(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order = order || ''
                this.listProject = []
                let searchCriteria = this.getProjectFilter(page, perPage)

                this.$http.get( '/projects/' + searchCriteria + order ).then( (response) => {
                    this.metaProject = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listProject = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putProjectUpdate(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/projects/' + this.objProject.pro_id, this.objProject ).then( (response) => {
                    this.objProject.pro_id = response.data.data.pro_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postProjectStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/projects', this.objProject ).then( (response) => {
                    this.objProject.pro_id = response.data.data.pro_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteObjProject(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/projects/' + this.objProject.pro_id, this.objProject ).then( () => {
                    this.objProject.pro_id = 0
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cleanObjProject(){
            var ProjectClone = JSON.parse( this.objProjectClone )
            var prop
            for( prop in ProjectClone ){
                if( this.objProject[prop] !== undefined ){
                    this.objProject[prop] = ProjectClone[prop]
                }
            }
        }
    }
}
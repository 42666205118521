<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" color="barrinfo">
        <v-toolbar-title >Utilisateurs</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn to="/users/0" color="success" depressed class="white--text">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Nouveau</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo single-line hide-details clearable v-model="search" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="usr_id" :search="search"
        items-per-page-text="Lignes par page" hide-default-header
        :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.usr_id" style="cursor:pointer;">
            <td class="pt-2 text-xs-left">
                <strong flat color="blue" class="ma-0 subheading text-uppercase blue--text">{{ item.usr_lastName }}</strong>
                &nbsp;<span flat color="blue" class="ma-0 subheading text-capitalize blue--text">{{ item.usr_firstName }}</span>
                <br />
                <span class="ma-0 pl-0">
                    <v-icon small>mdi-account</v-icon>
                    <span class="grey--text">{{ item.usr_username }}</span>
                    &nbsp;<v-icon small>mdi-email-outline</v-icon>
                    <span class="grey--text">{{ item.usr_email }}</span>
                </span>
            </td>
            <td class="text-xs-left"></td>
            <td class="text-xs-left">
                <v-chip :active="true" outlined small label color="primary" >{{ item.usr_role }}</v-chip>
            </td>
            <td class="text-right">
                <v-icon left class="mr-2">mdi-chart-histogram</v-icon>
                <span class="grey--text font-italic">{{ locDateFormat.toLocale(item.usr_updated_at, { year: 'numeric', month: 'long', day: 'numeric' }) }}</span>
                <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/users/' + item.usr_id + ''">
                    <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
                </v-btn>
            </td>
        </tr>
        </tbody>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
    <v-dialog v-model="modalState" max-width="1000" @keydown.esc="modalState = false" :persistent="false">
        <v-card style="z-index:1100;">
        <v-toolbar color="primary" dark class="elevation-2" height="50px">
            <v-icon class="mr-3">mdi-chevron-down</v-icon>
            <v-toolbar-title>Erreur de log</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="modalState = false"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text >
            {{ errorLog }}
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';

export default {
    name: 'user',
    props: ['toast'],
    data () {
        return {
            max25chars: (v) => v.length <= 25 || 'Trop de charactères',
            tmp: '',
            search: '',
            modalState: false,
            errorLog: 'Aucune erreur',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom', align: 'left', value: 'usr_lastName'},
            { text: 'Mail', align: 'left', value: 'usr_email'},
            { text: 'Role', value: 'usr_role' },
            { text: 'Création', value: 'usr_created_at' }
            ],
            items: [],
            userRole: {
                'BASIC_USER'      : 'Utilisateur (aucun droit)' ,
                'ADMIN_USER'      : 'Administrateur' ,
                'SUPER_USER'      : 'Superviseur' ,
                //'WORK_SUPERVISOR' : 'Superviseur Atelier' ,
                //'TEAM_LEADER'     : 'Chef Atelier' ,
                'AGENT'           : 'Agent' ,
                'EXTERN_USER'     : 'Utilisateur interne' ,
                'PARTNER_USER'    : 'Utilisateur externe' ,
            },
            locDateFormat: dateLocale, // Format de date
        }
    },
    mounted: function (){
        this.$http.get( '/users' ).then( (response) => {
            this.items = response.data.data
        })
    },
    methods: {
        goRoute(id){
            if( this.$user.usrGetters.getScopeRight('users', 'read') ){
                this.$router.push({ path: '/users/' + id })
            } else {
                this.toast({ text: 'Droits insuffisant pour modifier une fiche' })
            }
        },
        getErrorLog(){
            this.$http.get( '/errorlog' ).then( (response) => {
                this.errorLog = response.data
                this.modalState = true
            })
        }
    }
}
</script>

<style lang="css">

</style>
<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/lists/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche liste</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
<!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
        <v-card-text>
            <v-layout row wrap>
            <v-flex xs6 class="pa-2">
                <v-text-field label="Nom"   v-model="form.lst_label" ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-text-field label="Code"   v-model="form.lst_code" ></v-text-field>
            </v-flex>
            <v-flex xs3 class="pa-2">
                <v-select 
                    item-text="sta_label" item-value="sta_id"
                    :items="getCacheState('GEN')" v-model="form.lst_sta_id" label="Statut" single-line >
                </v-select>
            </v-flex>
            </v-layout>
        </v-card-text>
        <v-card-actions>
            <v-btn outlined color="orange" :loading="saveProgress" @click.native="lsdLinkModal = true" :disabled="saveProgress">
                <v-icon dark>mdi-vector-link</v-icon> Associer
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" :loading="saveProgress" @click.native="saveForm()" :disabled="saveProgress">Enregistrer</v-btn>
            <v-btn color="primmary" href="/#/lists/">Retour</v-btn>
        </v-card-actions>
    </v-card>

    <v-data-table v-if="form.lst_id" class="mt-4 ml-2 mr-2 elevation-2" v-bind:headers="headers" :items="items" item-key="obt_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page="pagination.rowsPerPage" :footer-props="footer">
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>
                    <v-alert class="mb-0" border="left" dense colored-border type="info">Liste</v-alert>
                </v-toolbar-title>
                <v-spacer></v-spacer>
            <v-btn outlined rounded small :color="'green'" @click="openListItem(0)"> <!-- :disabled="!routeState.edit" -->
                Ajouter<v-icon>mdi-playlist-edit</v-icon>
            </v-btn>
            </v-toolbar>
        </template>
        <template v-slot:[`item.lsd_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.lsd_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.lsd_lsd_id_link`]="{ item }">
            <v-chip small outlined label :color="( item.lsd_lsd_id_link ) ? 'success': 'error'">{{ ( item.lsd_lsd_id_link ) ? 'oui' : 'non' }}</v-chip>
            <span class="ml-1 text-truncate" v-show="( item.lsd_lsd_id_link )">({{item.lsd_label_link}})</span>
        </template>
        <template v-slot:[`item.lsd_id`]="{ item }">
            <v-btn icon color="orange" @click="openListItem(item.lsd_id)">
                <v-icon left color="orange">mdi-pencil</v-icon>
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>

    <!-- Modal de creation -->
    <div class="text-xs-center">
        <v-dialog v-model="lsdModal" width="500">
            <component v-if="lsdModal" :toast="toast" :is="'listitem-edit'" :lsd_id="lsd_id" :switchModal="switchModal" ></component>
        </v-dialog>
        <v-dialog v-model="lsdLinkModal" width="500">
            <component v-if="lsdLinkModal" :toast="toast" :is="'listitem-link'" :lst_id="form.lst_id" :switchModal="switchModal" ></component>
        </v-dialog>
    </div>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"
import editListitem  from '../components/Edit_listitem.vue'
import editListlink  from '../components/Edit_listlink.vue'

export default {
    name: 'user_card',
    props: ['toast'],
    data: function () {
        return {
            breadItem: [
                { text: 'Modèle', disabled: false, href: '/#/lists/' },
            ],
            search: '',
            footer: { 'items-per-page-all-text':"tout", 'items-per-page-text':'Ligne par page' },
            pagination: { totalItems: 0, rowsPerPage: 5, rowsPerItem: [5,15,25,35,{text:'Tout',value:-1}] },
            saveProgress: false,         // Enregistrement en cours...
            form:{
                lst_id: 0, lst_sta_id: 'GEN01', lst_label: '', lst_code: '',
                lst_usr_id_creat: null, lst_created_at: null, lst_updated_at: null
            },
            locDateFormat: dateLocale, // Format de date
            items: [],
            headers: [
                { text: 'Libellé', align: 'left',  value: 'lsd_label'},
                { text: 'Code',    align: 'left',  value: 'lsd_code'},
                { text: 'Associé',  align: 'left', value: 'lsd_lsd_id_link' },
                { text: 'Statut',  align: 'right', value: 'lsd_sta_id' },
                { text: 'Action',  align: 'right', value: 'lsd_id' }
            ],
            lsdModal: false,
            lsdLinkModal: false,
            lsd_id: 0
        }
    },
    computed: {

    },
    mounted:function (){
        if( parseInt( this.$route.params.lst_id ) > 0 && this.$route.params.lst_id !== undefined ){
            this.form.lst_id = this.$route.params.lst_id
            this.breadItem.push( { text: '#' + this.form.lst_id, disabled: false } ),
            this.getListTemplate()
            this.getListItems()
        }
    },
    methods: {
        async getListTemplate(){
            this.$http.get( '/listtemplates/' + this.$route.params.lst_id )
            .then( (response) => {
                var prop
                for( prop in response.data.data ){
                    if( this.form[prop] !== undefined ){
                        this.form[prop] = response.data.data[prop] 
                    }
                }
            })
            .catch( (error) => {
                this.toast({ color: 'warning', text: error })
            })
        },
        getListItems(){
            this.$http.get( '/listdatas/?per_page=false&lsd_lst_id=' + this.form.lst_id + '&extended=withlink' )
            .then( (response) => {
                this.items = response.data.data
            })
        },
        saveForm () {
            // Enregistrement du formulaire
            this.saveProgress = true
            if( this.form.lst_id > 0 ){
                this.$http.put( '/listtemplates/' + this.form.lst_id, this.form )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            } else {
                this.$http.post( '/listtemplates/', this.form ).then( (response) => {
                    for( let prop in response.data.data ){
                        if( this.form[prop] !== undefined ){
                            this.form[prop] = response.data.data[prop] 
                        }
                    }
                    this.saveProgress = false
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.$router.push({ path: '/lists/' + this.form.lst_id })
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.saveProgress = false
                });
            }
        },
        openListItem(id){
            this.lsd_id   = id
            this.lsdModal = true
        },
        switchModal(name, mode, refresh){
            name    = name || 'lsdModal'
            mode    = mode || 0
            //refresh = refresh || false
            this[name] = mode
            if( mode == 0 ){
                this.lst_id = 0
            }
            if( refresh ){
                this.getListItems()
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        'listitem-edit': editListitem,
        'listitem-link': editListlink
    }
}
</script>
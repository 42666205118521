/* *****************************
    Objet : Equipment
******************************** */
import { cacheGetters } from "../store/cache.module"

export const equObj = {
    data: function () {
        return {
            objEquipment: {
                equ_id     : 0, equ_uid : '', equ_pro_id: null,
                equ_sta_id : 'GEN01', equ_typ_id : 'EQU01',
                equ_name : '', equ_serial : '', equ_customfields : {},
                equ_usr_id_created : 0, equ_created_at : '',
            },
            objEquipmentClone: {},
            listEquipment: [],
            metaEquipment: {},
            //bookmark: cacheGetters.getBookmark()
        }
    },
    computed: {
        //objEquipmentInfo(){
        //    let type = cacheGetters.getTypes( 'SIT', this.objEquipment.equ_typ_id )
        //    return {
        //        typeContract: type.typ_tag
        //    }
        //}
    },
    beforeMount: function (){
        this.objEquipmentClone = JSON.stringify( this.objEquipment )
    },
    methods: {
        getEquipmentFilter(page, perPage){
            page    = page || 1
            perPage = perPage || 'false'
            let filter = cacheGetters.getFilter()
            var searchCriteria = '?page='+ page +'&per_page=' + perPage + '&extended=true'
            for( var prop in filter ){
                if( filter[prop] !== undefined && filter[prop].length > 0 ){
                    searchCriteria += '&' + prop + '=' + filter[prop]
                }
            }
            return searchCriteria
        },
        getEquipmentShow(options){
            return new Promise( (resolve, reject) => {
                options = options || ''
                if( options.length > 0 ){
                    options = '/?include=' + options
                }
                this.$http.get( '/equipments/' + this.objEquipment.equ_id + options).then( (response) => {
                    var prop
                    for( prop in response.data.data ){
                        if( typeof response.data.data[prop] === 'object' && response.data.data[prop] !== null && response.data.data[prop]['data'] !== undefined ){
                            this.objEquipment._info[prop] = response.data.data[prop]['data']
                        } else {
                            this.objEquipment[prop] = response.data.data[prop]
                        }
                    }
                    this.objEquipment.equ_customfields = (!this.objEquipment.equ_customfields) ? {} : this.objEquipment.equ_customfields
                    if( Object.prototype.toString.call( this.objEquipment.equ_customfields ) === '[object Array]' ) {
                        this.objEquipment.equ_customfields = {}
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        getEquipmentIndex(page, perPage, order){
            return new Promise( (resolve, reject) => {
                order = order || ''
                this.listEquipment = []
                let searchCriteria = this.getEquipmentFilter(page, perPage)

                this.$http.get( '/equipments/' + searchCriteria + order ).then( (response) => {
                    this.metaEquipment = response.data.meta
                    if( Object.prototype.toString.call( response.data.data ) === '[object Array]' ) {
                        this.listEquipment = response.data.data
                    }
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        postEquipmentStore(){
            return new Promise( (resolve, reject) => {
                this.$http.post( '/equipments', this.objEquipment ).then( (response) => {
                    this.objEquipment.equ_id = response.data.data.equ_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        putEquipmentStore(){
            return new Promise( (resolve, reject) => {
                this.$http.put( '/equipments/' + this.objEquipment.equ_id, this.objEquipment ).then( (response) => {
                    this.objEquipment.equ_id = response.data.data.equ_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        deleteEquipmentDestroy(){
            return new Promise( (resolve, reject) => {
                this.$http.delete( '/equipments/' + this.objEquipment.equ_id ).then( (response) => {
                    this.objEquipment.equ_id = response.data.equ_id
                    resolve('resolved')
                }).catch( (error) => { 
                    reject(error)
                })
            })
        },
        cloneEquipmentObj(){
            this.objEquipmentClone = JSON.stringify( this.objEquipment )
        },
        cleanEquipmentObj(){
            var equipmentClone = JSON.parse( this.objEquipmentClone )
            var prop
            for( prop in equipmentClone ){
                if( this.objEquipment[prop] !== undefined ){
                    this.objEquipment[prop] = equipmentClone[prop]
                }
            }
            //this.equipmentClone = Object.assign({}, this.objEquipment)
        },
    }
}
<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-2" height="48px">
        <v-btn icon href="/#/projects/"><v-icon>mdi-arrow-left-thick</v-icon></v-btn>
        <v-toolbar-title >Fiche projet</v-toolbar-title>
        <v-spacer></v-spacer>
    </v-toolbar>
    <!-- Formulaire -->
    <v-card fluid class="ma-2 pa-5 elevation-2">
    <v-layout wrap>
    <v-flex xs12 class="pa-2">
        <v-text-field label="Nom projet" v-model="objProject.pro_label" ></v-text-field>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-select 
            item-text="sta_label" item-value="sta_id"
            :items="getCacheState('GEN')" v-model="objProject.pro_sta_id" label="Statut" single-line ></v-select>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-select 
        item-text="typ_name" item-value="typ_id"
        :items="getCacheType('PRJ')" color="grey lighten-5" v-model="objProject.pro_typ_id_projection" label="Projection" single-line ></v-select>
    </v-flex>
    <v-flex xs6 class="pa-2">
            <v-menu ref="datestart" v-model="dateMenu.date_start"
                :return-value.sync="objProject.pro_date_cal"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objProject.pro_date_cal, 'fr' )"
                        label="Date début" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objProject.pro_date_cal">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_start = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.datestart.save( objProject.pro_date_cal )">OK</v-btn>
                </v-date-picker>
            </v-menu>
    </v-flex>
    <v-flex xs6 class="pa-2">
            <v-menu ref="dateend" v-model="dateMenu.date_end"
                :return-value.sync="objProject.pro_date_cal_end"
                :close-on-content-click="false" :nudge-right="40" transition="scale-transition" 
                offset-y min-width="290px" style="z-index:1500">
                <template v-slot:activator="{ on, attrs }">
                    <v-text-field 
                        v-bind="attrs" :value="locDateFormat.formatLocale( objProject.pro_date_cal_end, 'fr' )"
                        label="Date fin" prepend-icon="mdi-calendar-today" readonly v-on="on" >
                    </v-text-field>
                </template>
                <v-date-picker locale="fr-fr" no-title scrollable v-model="objProject.pro_date_cal_end">
                    <v-spacer></v-spacer>
                    <v-btn color="primary" @click="dateMenu.date_end = false">Annuler</v-btn>
                    <v-btn color="primary" @click="$refs.dateend.save( objProject.pro_date_cal_end )">OK</v-btn>
                </v-date-picker>
            </v-menu>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-textarea solo label="Commentaire" v-model="objProject.pro_comment" counter="255"></v-textarea>
    </v-flex>
    <v-flex xs6 class="pa-2" fluid :class="{ 'blue': dragover }" 
            @drop.prevent="onDrop($event)"
            @dragover.prevent="dragover = true"
            @dragenter.prevent="dragover = true"
            @dragleave.prevent="dragover = false">
            <v-container fill-height fluid>
            <v-row align="center" justify="center">
                <v-col v-if="!objProject.pro_doc_id_logo" cols="12" sm="4"><v-btn color="grey" @click="switchModal('modalDocument', 1)">Ajouter un logo</v-btn></v-col>
                <v-col v-else-if="objProject.pro_doc_id_logo" cols="12" sm="4">
                    <img :src="project_logo" style="max-height: 150px" @click="switchModal('modalDocument', 1)">  
                </v-col>
            </v-row>
            </v-container>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-autocomplete
            v-model="searchAddress"
            label="Rechercher une adresse"
            :items="addressTab"
            item-value="id" item-text="label"
            :search-input.sync="search"
            @input="selectAdress"
            append-icon="mdi-search"
        ></v-autocomplete>
        <v-text-field prepend-icon="mdi-town-hall" label="Ville" v-model="objProject.pro_town" ></v-text-field>
        <v-text-field prepend-icon="mdi-map-check-outline" label="Code postale" v-model="objProject.pro_zip" ></v-text-field>
        <v-text-field prepend-icon="mdi-map-marker" label="Adresse" v-model="objProject.pro_address" ></v-text-field>
    </v-flex>
    <v-flex xs6 class="pa-2">
        <v-text-field prepend-icon="mdi-latitude" label="Latitude" v-model="objProject.pro_lat" ></v-text-field>
        <v-text-field prepend-icon="mdi-longitude" label="Longitude" v-model="objProject.pro_lng" ></v-text-field>
        <v-select 
        item-text="obt_label" item-value="obt_id" prepend-icon="mdi-sprout-outline"
        :items="objectType" color="grey lighten-5" v-model="objProject.pro_obt_id_phyto" label="Forumlaire Phyto" single-line ></v-select>
    </v-flex>
    <v-flex xs6 class="pa-2"></v-flex>
    <v-flex xs6 class="pa-2">
        <AutocompleteMulti v-if="!loading.getItem"
            :title="'Filtres personnalisés'" 
            ressource="cardtemplates"
            :listObject.sync="objProject.pro_custom_filter" :listParent.sync="filterSource.fields" 
            :fields="{ 
                index: 'ctp_id', text:'ctp_label', autofocus: true, outlined: true,  dense: true,
                search:'ctp_label', multiSelect:true, params:'ctp_data_type=LIST', prefetch:true
        }"></AutocompleteMulti>
    </v-flex>
    </v-layout>
    </v-card>
    <div class="text-right mt-3 mr-3">
        <v-btn class="mr-2" color="success" @click.native="saveProject()" :loading="saveProgress" :disabled="saveProgress">
        Enregistrer
        </v-btn>
        <v-btn color="primmary" href="/#/projects/">
        Retour
        </v-btn>
    </div>
    <!-- Logo / photo -->
    <v-dialog v-model="modalDocument" max-width="900" >
        <v-card color="grey lighten-4" min-width="350px" flat >
        <v-toolbar color="primary" dark class="elevation-1" height="50px">
            <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
            <v-toolbar-title>Document</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon color="" @click.native="switchModal('modalDocument', 0)"><v-icon>mdi-close</v-icon></v-btn>
        </v-toolbar>
        <v-card-text class="mt-2">
            <edit-document class="elevation-2" 
                v-if="modalDocument" :toast="toast" :switchModal="switchModal"
                :xxxId="objProject.pro_id" :docId.sync="doc_id" :docTable="'pro'" :docType="'DLO'" :withFile="uploadedFiles">
            </edit-document>
        </v-card-text>
        </v-card>
    </v-dialog>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { proObj } from '../mixins/pro_obj.js'
import { cacheGetters } from "../store/cache.module"
import editDocument from '../components/Edit_document.vue'
import AutocompleteMulti from '@/components/ui/AutocompleteMulti'

export default {
    name: 'projectedit',
    props: ['toast'], 
    mixins: [ proObj ],
    components: {
        'edit-document'   : editDocument, AutocompleteMulti
    },
    data: function () {
        return {
            loading: { getItem: true },
            dateMenu: { date_start:false, date_end:false },
            saveProgress: false,         // Enregistrement en cours...
            locDateFormat: dateLocale, // Format de date
            datePro: { start: false, end: false },
            dragover: false,
            modalDocument: false,
            uploadedFiles: undefined,
            doc_id: undefined,
            project_logo: '',
            searchAddress: null,
            addressTab: [],
            search: null,
            searchWord: '',
            timeOutHandle: 'off',
            objectType: [],
            filterSource: {
                fields: []
            }
        }
    },
    watch: {
        search (val) {
            this.searchWord = val
            if( this.timeOutHandle == 'off' ){
                this.timeOutHandle = 'on'
                this.searchTempo = setTimeout( () => {
                    this.loadAddress(this.searchWord)
                    this.timeOutHandle = 'off'
                }, 1000)
            }
        },
    },
    beforeMount: function(){
        this.objProject._table = { user: [] }
    },
    mounted:function (){
        if( this.$route.params.pro_id !== undefined && this.$route.params.pro_id > 0 ){
            this.objProject.pro_id = this.$route.params.pro_id
            this.getItem()
            this.getObjectType()
        } else {
            this.loading.getItem = false
        }
    },
    methods: {
        async getItem(){
            await this.getProjectShow()
            if( this.objProject.pro_doc_id_logo ){
                this.doc_id = this.objProject.pro_doc_id_logo
                this.printDocument(this.doc_id)
            }
            this.loading.getItem = false
        },
        saveProject(closeSheet){
            closeSheet = closeSheet || true
            if( this.objProject.pro_id !== undefined && this.objProject.pro_id > 0 ){
                this.putProjectUpdate().then( () => {
                    if( closeSheet ){
                        this.$router.push({ path: '/projects/' + this.objProject.pro_id + '/view' })
                    }
                })
            } else {
                this.postProjectStore().then( () => {
                    this.$router.push({ path: '/projects/' + this.objProject.pro_id + '/view' })
                })
            }
        },
        onDrop(e) {
            this.dragover = false;
            if (e.dataTransfer.files.length > 1) {
                this.toast({ color: 'error', text: 'Un seul fichier à la fois.' })
            } else {
                e.dataTransfer.files.forEach(element =>
                    this.uploadedFiles = element
                )
                this.doc_id = this.objProject.pro_doc_id_logo
                this.switchModal('modalDocument', 1)
            }
        },
        switchModal(name, mode){
            name    = name || 'modalDocument'
            mode    = mode || 0
            this[name] = mode
            if( mode == 0 ){
                this.uploadedFiles = undefined
            }
            if( name == 'modalDocument' && !mode && this.doc_id ){
                if( this.objProject.pro_doc_id_logo !== this.doc_id ){
                    this.objProject.pro_doc_id_logo = this.doc_id
                    this.saveProject(false)
                }
            }
        },
        printDocument(doc_id){
            this.$http.customRequest({ method: 'get', url: 'documents/' + doc_id + '/download', responseType: 'blob' } )
            .then( (response) => {
                if( response.status == 204 ){
                    this.toast({ color: 'warning', text: 'Logo introuvable' })
                } else {
                    this.project_logo = URL.createObjectURL(response.data)
                }
            }).catch( (error) => {
                this.toast({ color: 'error', text: error })
            })
        },
        loadAddress (val) {
            if( val.length > 1 ){
                this.addressTab = []
                this.$http.customRequest( {
                    headers: '',
                    baseURL: '',
                    method: 'get',
                    url: "https://api-adresse.data.gouv.fr/search/?q=" + val + "&limit=6"
                })
                .then( (response) => {
                    let data = []
                    let address, key
                    for (key = 0; (address = response.data.features[key]) !== undefined; key++) {
                        data[key] = { label: address.properties.label, value: address.properties.id, id:key, item: address }
                    }
                    this.addressTab = data
                })
            } else {
                return false
            }
        },
        selectAdress(){
            if( this.searchAddress !== null && this.searchAddress !== undefined ){
                this.objProject.pro_town = this.addressTab[ this.searchAddress ].item.properties.city
                this.objProject.pro_zip  = this.addressTab[ this.searchAddress ].item.properties.postcode
                this.objProject.pro_lat  = this.addressTab[ this.searchAddress ].item.geometry.coordinates[1]
                this.objProject.pro_lng  = this.addressTab[ this.searchAddress ].item.geometry.coordinates[0]
            }
        },
        getObjectType(){
            this.$http.get( '/objecttypes/?per_page=false' ).then( (response) => {
                this.objectType = response.data.data
            })
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    }
}
</script>
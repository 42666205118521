<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" height="48px">
        <v-toolbar-title >Gestion des projets</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn color="success" depressed class="white--text" to="/projects/0/edit">
            <v-icon left dark>mdi-plus-circle</v-icon>
            <span class="hidden-sm-and-down">Créer</span>
        </v-btn>
    </v-toolbar>
    <v-text-field
        append-icon="mdi-magnify" label="Recherche"
        solo clearable single-line hide-details v-model="search" @blur="saveSearchToCache()" class="ma-4 pa-0">
    </v-text-field>
    <v-card class="ma-4">
    <v-data-table v-bind:headers="headers" :items="items" item-key="sta_id" :search="search"
        items-per-page-text="Lignes par page" :items-per-page-options="pagination.rowsPerItem">
        <template v-slot:[`item.pro_label`]="{ item }">
            {{ item.pro_label }}
        </template>
        <template v-slot:[`item.pro_typ_id_projection`]="{ item }">
            <v-chip small outlined label>{{ getCacheType('PRJ', item.pro_typ_id_projection).typ_name }}</v-chip>
        </template>
        <template v-slot:[`item.pro_sta_id`]="{ item }">
            <v-chip small outlined label>{{ getCacheState('GEN', item.pro_sta_id).sta_label }}</v-chip>
        </template>
        <template v-slot:[`item.pro_id`]="{ item }">
            <v-btn outlined small color="grey" class="ml-2 elevation-1" :href="'/#/projects/' + item.pro_id + '/view'">
                <v-icon left class="mr-2">mdi-pencil</v-icon>Modifier
            </v-btn>
        </template>
        <template slot="pageText" slot-scope="{ pageStart, pageStop }">
        De {{ pageStart }} à {{ pageStop }}
        </template>
    </v-data-table>
    </v-card>
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters, cacheMutations } from "../store/cache.module"

export default {
    name: 'projects',
    props: ['toast'],
    data: function () {
        return {
            saveProgress: false,         // Enregistrement en cours...
            search: '',
            pagination: { totalItems: 0, rowsPerPage: 10, rowsPerItem: [15,25,35,{text:'Tout',value:-1}] },
            headers: [
            { text: 'Nom',          align: 'left', value: 'pro_label'},
            { text: 'Projection',   align: 'left', value: 'pro_typ_id_projection'},
            { text: 'Statut',       align: 'left', value: 'pro_sta_id'},
            { text: 'Début',        value: 'pro_date_cal'},
            { text: 'Fin',          value: 'pro_date_cal_end' },
            { text: 'Modification', value: 'pro_id' }
            ],
            items: [],
            locDateFormat: dateLocale,
            enableAdd: true,
            modalEvent : false,
            cacheTab: this.$storage.localStorage.get( this.$APP_COD + "_cache" ),
        }
    },
    computed: {
        pages () {
            if (this.pagination.rowsPerPage == null || this.pagination.totalItems == null ) return 0
            return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
        }
    },
    mounted: function (){
        this.search = cacheGetters.getSearchBarItem('projects') || ''
        this.getItems()
    },
    methods: {
        getItems(){
            this.$http.get( '/projects?per_page=false' ).then( (response) => {
                this.items = response.data.data
            })
        },
        handlePage(index){
            this.pagination.limit = this.pagination.rowsPerPage * (index - 1)
        },
        saveSearchToCache(){
            cacheMutations.setSearchBarItem('projects', this.search)
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates
    },
    components: {
        
    }
}
</script>
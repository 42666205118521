<template>
  <div class="px-1" :title="event.tsk_obs" @click="openMap()">
    <v-btn x-small icon class="mb-1 mr-2" color="white">
      <v-icon :color="'white'" small class="mr-1">mdi-map</v-icon>
    </v-btn>
    <span class="font-weight-bold">{{ event.title }}</span>
  </div>
</template>

<script>

export default {
  mixins: [],
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  methods: {
    openMap(){
      if( this.event.obj.tre_lat && this.event.obj.tre_lng ){
        this.$router.push({ path: '/map/center/' + this.event.obj.tre_lat + ',' + this.event.obj.tre_lng })
      }
    }
  }
}
</script>

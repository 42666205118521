<template>
<v-app id="login" light>
      <v-card class="elevation-1 pa-2 mx-auto ma-4">
        <v-card-title primary-title>
            <v-card-text>
            <v-row align="center" class="mx-0">
            <v-img :src='images.logo' class="mx-1" alt='logo' max-width="100" aspect-ratio="1"></v-img>
            <!-- <div class="my-4 text-subtitle-1">Changement de mot de passe</div> -->
            </v-row>
            </v-card-text>

            <v-card-title primary-title class="justify-center">
                <div>Changement de mot de passe</div>
            </v-card-title>

            <v-card-text v-if="oAuth && !passwordSet">
            <v-form v-model="valid">
                <v-text-field class="pt-1 login-input" @focus="scrollUp()"
                    @keyup.enter="passFocus()" label="Mot de passe" v-model="formData.password"
                    prepend-icon="mdi-lock-outline" :rules="nameRules" required lazy-validation
                    :type="'password'" :messages="[
                        'Le mot de passe doit comporter au moins 6 caractères.'
                    ]">
                </v-text-field>
                <v-text-field class="pt-1" ref="passwordField" label="Confirmer le mot de passe" 
                    @focus="scrollUp()" @keyup.native.enter="connect()"
                    v-model="formData.passwordconfirm"
                    :type="'password'" prepend-icon="mdi-lock-check-outline" :error-messages="errorMessage" :error="badAuth">
                </v-text-field>
            </v-form>
           </v-card-text>
        </v-card-title>
        <v-card-actions v-if="oAuth && !passwordSet">
          <v-btn class="elevation-1" 
            v-if="oAuth"
            :disabled="( !(formData.password.length > 5) || (formData.passwordconfirm !== formData.password) )"
            @click="onSubmit" color="primary" light block>
            Enregistrer
          </v-btn>
        </v-card-actions>

        <v-card-text v-if="passwordSet">
            <div>Mot de passe enregistré avec succès !</div>
            <v-btn class="ma-2" outlined color="blue" href="/" block>
                Aller sur la page de connection
            </v-btn>
        </v-card-text>
        </v-card>

</v-app>
</template>

<script>

export default {
    name: 'setpass',
    data () {
        return {
            checkAuth: false,
            badAuth: false,
            errorMessage: [],
            formData : {
                password: '',
                passwordconfirm: '',
                token: ''
            },
            valid: false,
            nameRules: [ (v) => !!v || 'Pass obligatoire' ],
            passRules: [ (v) => !!v || 'Pass obligatoire' ],
            images: {
                logo     : require('../assets/logo.svg'),
                logoSmall: require('../assets/logo_small.svg')
            },
            oAuth: true,
            passwordSet: false
        }
    },
    computed: {
    },
    mounted:function (){
        if( ( this.$route.params.token ) && this.$route.params.token.length > 3 ){
            this.formData.token = this.$route.params.token
        } else {
            this.errorMessage.push('Votre autorisation a expirée')
        }
    },
    methods: {
        async onSubmit() {
            this.$http.post( '/setPassword/', this.formData)
            .then( () => {
                this.passwordSet = true
            })
            .catch( () => {
                this.errorMessage.push('Votre autorisation a expirée')
            }) 
        },
        passFocus(){
            this.$refs.passwordField.focus()
        },
        scrollUp(){
            var el = this.$el.getElementsByClassName("login-input")[0];
            el.scrollIntoView();
        }
    },
    components: { }
}
</script>

<style lang="css">
/* Personnalisation code */
.login-container {
  transform: translate(0, -50px);
  padding-top: 2em;
}
.login-paper {
  width: 24em;
  text-align: center;
  margin-right: auto;
  margin-left: auto;
}
.login-logo {
  margin-bottom: 1.2em;
  padding-top: 0.5em;
}

</style>
<template>
<v-container fluid class="pa-0">
<v-card>
    <v-toolbar class="elevation-1" color="primary" height="48px">
        <v-toolbar-title >Champ de donnée {{ (ctp_id) ? '#' + ctp_id : '(nouveau)' }}</v-toolbar-title>
    </v-toolbar>
    <v-card-text>
    <v-layout row wrap>
        <v-flex xs12 class="pa-2">
            <v-text-field label="Libellé" v-model="ctpObj.ctp_label" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-text-field label="Code" v-model="ctpObj.ctp_code" ></v-text-field>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="typInfo" v-model="ctpObj.ctp_typ_id_section" label="Section" single-line >
            </v-select>
        </v-flex>
        <v-flex xs3 class="pa-2">
            <v-text-field label="Ordre" v-model="ctpObj.ctp_pos_row" mask="###" ></v-text-field>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="sta_label" item-value="sta_id"
                :items="getCacheState('GEN')" v-model="ctpObj.ctp_sta_id" label="Statut" single-line >
            </v-select>
            <v-select 
                item-text="typ_description" item-value="typ_name"
                :items="getCacheType('FDT')" v-model="ctpObj.ctp_data_type" label="Type" single-line >
            </v-select>
        </v-flex>
        <v-flex xs6 class="pa-2">
            <v-select 
                item-text="typ_name" item-value="typ_id"
                :items="getCacheType('SHA')" v-model="ctpObj.ctp_typ_id" label="Objet" single-line >
            </v-select>
            <v-select v-if="ctpObj.ctp_data_type == 'LIST'" :items="state.lst" item-text="lst_label" item-value="lst_id" v-model="ctpObj.ctp_lst_id" label="Liste" single-line ></v-select>
        </v-flex>
    </v-layout>
    <div class="text-xs-right">
        <v-btn color="success" :loading="saveProgress" @click.native="saveCtp()" :disabled="saveProgress">Enregistrer</v-btn>
        <v-btn color="primmary" @click.native="switchModal('ctpModal', 0, false)">Retour</v-btn>
    </div>
    </v-card-text>
</v-card> 
</v-container>
</template>

<script>
import dateLocale from '../services/dateLocale';
import { cacheGetters } from "../store/cache.module"

export default {
    name: 'cardtemplate_edit',
    props: ['toast', 'ctp_id', 'switchModal'],
    data: function () {
      return {
        saveProgress: false,         // Enregistrement en cours...
        locDateFormat: dateLocale, // Format de date
        state: {
            lst: []
        },
        ctpObj: {
            ctp_id: 0, ctp_obt_id: 0, ctp_sta_id: 'GEN01', ctp_typ_id: 'SHA01', ctp_typ_id_section: 'CTP01',
            ctp_label: '', ctp_data_type: 'VARCHAR', ctp_lst_id: null, ctp_pos_row: 0, ctp_pos_col: 0, ctp_usr_id_creat: null
        },
        ctpObjClone:'',
        typInfo    : []
      }
    },
    beforeMount: function(){
        this.typInfo = cacheGetters.getTypes('CTP')
        this.typInfo.push({
            typ_olor: "#757575",
            typ_created_at: "2022-01-03 12:09:48",
            typ_description: "-",
            typ_entity: "TEMPLATE",
            typ_id: null,
            typ_name: "Non définit",
            typ_tag: "CTP",
            typ_updated_at: "",
            typ_visibility: 1,
        })
    },
    mounted: function (){
        this.state.lst = this.getCacheList()
        this.state.lst.unshift( { lst_id: null, lst_sta_id: 'GEN01', lst_label: 'Aucune', lst_usr_id_creat: null } )
        if( this.$route.params.tem_id !== undefined && this.$route.params.tem_id > 0 ){
            this.ctpObj.ctp_tem_id = this.$route.params.tem_id
            this.ctpObj.ctp_obt_id = this.$route.params.obt_id
        } else {
            this.$router.push({ path: '/themes/' })
        }
        this.ctpObjClone = JSON.stringify( this.ctpObj )
        if( this.ctp_id > 0 ){
            this.getCtp()
        }
    },
    methods: {
        getCtp(){
            this.$http.get( '/cardtemplates/' + this.ctp_id )
            .then( (response) => {
                for( let prop in response.data.data ){
                    //if( this.ctpObj[prop] !== undefined ){
                        this.ctpObj[prop] = response.data.data[prop]
                    //}
                }
            })
        },
        saveCtp(){
            if( this.ctpObj.ctp_id > 0 ){
                this.$http.put( '/cardtemplates/' + this.ctpObj.ctp_id, this.ctpObj )
                .then( () => {
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                    this.switchModal('ctpModal', 0, true)
                }).catch( (error) => {
                    this.toast({ text: 'Erreur lors de l\'enregistrement !', error: error })
                    this.switchModal('ctpModal', 0, true)
                    this.saveProgress = false
                })
            } else {
                this.$http.post( '/cardtemplates', this.ctpObj ).then( () => {
                    //this.ctp_id = response.data.ctp_id
                    this.toast({ color: 'success', text: 'Fiche enregistrée !' })
                    this.saveProgress = false
                    this.switchModal('ctpModal', 0, true)
                })
            }
        },
        cleanObj(){
            var ctpClone = JSON.parse( this.ctpObjClone )
            var prop
            for( prop in ctpClone ){
                if( this.ctpObj[prop] !== undefined ){
                    this.ctpObj[prop] = ctpClone[prop]
                }
            }
        },
        getCacheType: cacheGetters.getTypes,
        getCacheState: cacheGetters.getStates,
        getCacheList: cacheGetters.getLists
    },
    components: { }
}
</script>
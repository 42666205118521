<template>
<v-container fluid class="pa-0">
    <v-toolbar class="elevation-1" color="barrinfo">
        <v-toolbar-title >Planning</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small outlined class="mr-4" color="grey darken-2" @click="focus = ''" >Aujourd'hui</v-btn>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn small outlined color="grey darken-2" v-bind="attrs" v-on="on" >
              <span>{{ typeToLabel[type] }}</span>
              <v-icon right>mdi-menu-down</v-icon>
            </v-btn>
          </template>
          <v-list>
            <template v-for="(item, index) in types">
              <v-list-item @click="type = item.value" :key="index">
                <v-list-item-title>{{item.text}}</v-list-item-title>
              </v-list-item>
            </template>
          </v-list>
        </v-menu>
        <v-btn small outlined class="ml-2" color="grey darken-2" @click="this.getEvents()" ><v-icon>mdi-refresh</v-icon></v-btn>
    </v-toolbar>
  <v-card class="ma-4 mb-2">
    <v-sheet tile height="54" class="d-flex">
      <v-toolbar dense flat>
        <v-toolbar-title v-if="$refs.calendar">{{ $refs.calendar.title }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-2" @click="$refs.calendar.prev()"><v-icon>mdi-chevron-left</v-icon></v-btn>
        <v-btn icon class="ma-2" @click="$refs.calendar.next()"><v-icon>mdi-chevron-right</v-icon></v-btn>        
        <!-- <v-select v-model="type" :items="types" dense outlined hide-details class="ma-2" label="type"></v-select> -->
      </v-toolbar>
    </v-sheet>
    <v-sheet height="600">
      <v-calendar
        ref="calendar" v-model="focus" :weekdays="weekday" :type="type" :events="events" :event-overlap-mode="mode" 
        :event-overlap-threshold="30" :event-color="getEventColor" @change="getEvents" 
        @click:date="createEvent" @click:more="viewDay"
      > <!-- @click:event="showEvent" -->
        <template #event="{ event, timed, eventSummary }">
          <CalendarEvent :event="event" />
        </template>
      </v-calendar>
    </v-sheet>
  </v-card>
  <v-dialog v-model="modalEvent" max-width="900" style="z-index:2066;">
      <v-card color="grey lighten-4" min-width="350px" flat >
      <v-toolbar color="primary" dark class="elevation-1" height="50px">
          <v-btn icon><v-icon>mdi-chevron-down</v-icon></v-btn>
          <v-toolbar-title>{{selectionEvent.title}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon color="" @click.native="switchModal('modalEvent', 0)"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
      <v-card-text class="mt-2">
          <EventForm class="elevation-2" 
              v-if="modalEvent" :toast="toast" :switchModal="switchModal"
              :xxxId="selectionEvent.xxxId" :eveId="selectionEvent.eveId" 
              :eveTable="selectionEvent.eveTable" :eveType="selectionEvent.eveType" 
              :title="selectionEvent.title" :cooridnates="selectionEvent.cooridnates"
              :start="selectionEvent.start">
          </EventForm>
      </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="modalListEvent" max-width="900" style="z-index:2066;">

  </v-dialog>
</v-container>
</template>

<script>
import { eveObj } from '../mixins/eve_obj.js'
import { cacheData, cacheGetters } from "../store/cache.module"
import EventForm        from '@/components/event/EventForm.vue'
import CalendarEvent from '@/components/calendar/CalendarEvent'

export default {
  name: 'calendar',
  mixins: [ eveObj ],
  props: ['toast'],
  components: { EventForm, CalendarEvent },
  data: () => ({
    loading: { getItem: true, getUnplanned: false },
    type: 'month',
    typeToLabel: { month: 'Mois', week: 'Semaine', day: 'Jour', '4day': '4 Jours', },
    types: [{ text: 'Mois', value: 'month' }, { text: 'Semaine', value: 'week' }, { text: 'Jour', value: 'day' }, { text: '4 Jours', value: '4day' },],
    mode: 'stack',
    modes: ['stack', 'column'],
    weekday: [1, 2, 3, 4, 5, 6, 0],
    weekdays: [
      { text: 'Lun - Dim', value: [1, 2, 3, 4, 5, 6, 0] },
      { text: 'Dim - Sam', value: [0, 1, 2, 3, 4, 5, 6] },
      { text: 'Lun - Ven', value: [1, 2, 3, 4, 5] },
    ],
    focus: '',
    events: [],
    colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1'],
    names: ['Réunion', 'Preventif', 'test', 'Déplacement', 'Evenement', 'Absence', 'Conference', 'tache'],
    modalEvent    : false,
    modalListEvent: false,
    selectionEvent: { xxxId: [], eveId:0, eveTable: "cad", eveType: "ACT", title:"Actions", cooridnates:null, start:null },
    calendarInfo: { start: null, end: null }
  }),
  computed: {
    user(){
        return cacheData.user
    },
  },
  methods: {
    getEvents( { start, end } ){
      clearTimeout( this.loadEvent )
      this.loading.getItem = true
      this.loadEvent = setTimeout( () => {
        this.loadEvents( start, end )
      }, 500)
    },
    async loadEvents ( start, end ) {
      this.calendarInfo.start = start 
      this.calendarInfo.end = end

      this.loading.getItem = true
      this.events  = []
      const events = []
      const types  = this.getCacheType()

      this.getEventIndex('?per_page=false&extended=withcard,withtree&min_eve_date_start=' + start.date + '&max_eve_date_start=' + end.date).then( () => {
        this.listEvent.forEach( (obj) => {
          let type = obj.eve_typ_id.substring(0, 3)
          events.push({
            name: obj.eve_title,
            start: new Date(obj.eve_date_start),
            //end: second,
            color: types[type][obj.eve_typ_id]['typ_color'], //this.colors[this.rnd(0, this.colors.length - 1)],
            //timed: !allDay,
            eve_id       : obj.eve_id,
            eve_table_id : obj.eve_table_id,
            eve_typ_id   : obj.eve_typ_id,
            title        : types[type][obj.eve_typ_id]['typ_name'],
            obj          : obj
          })
        });
      })
      this.events = events
    },
    getEventColor (event) {
      return event.color
    },
    rnd (a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a
    },
    switchModal(name, mode){
        name    = name || 'dialogObj'
        mode    = mode || false
        //refresh = refresh || false
        this[name] = mode
        if( !mode ){
          this.getEvents(this.calendarInfo)
        }
    },
    showEvent(event){
      this.selectionEvent.start    = null
      this.selectionEvent.xxxId    = this.user.usr_pro_id
      this.selectionEvent.eveId    = event.event.eve_id
      this.selectionEvent.eveTable = event.event.eve_table_id
      this.selectionEvent.eveType  = event.event.eve_typ_id.substring(0, 3)
      this.selectionEvent.title    = 'Alerte'
      let Tabtitle = { 'ACT': 'Action', 'ALT': 'Alerte' }
      if( Tabtitle[this.selectionEvent.eveType] ){
        this.selectionEvent.title    = Tabtitle[this.selectionEvent.eveType]
      }

      this.switchModal('modalEvent', 1)
    },
    createEvent(info){
      this.selectionEvent.start    = info.date
      this.selectionEvent.xxxId    = this.user.usr_pro_id
      this.selectionEvent.eveId    = 0
      this.selectionEvent.eveTable = 'pro'
      this.selectionEvent.eveType  = 'ACT'
      this.selectionEvent.title    = 'Action'
      this.switchModal('modalEvent', 1)
    },
    viewDay ({ date }) {
      this.focus = date
      this.type = 'day'
    },
    showMoreEvent(info){
      console.log(info)
    },
    getCacheType: cacheGetters.getTypes,
  }
}
</script>
<template>
    <router-view ></router-view>
</template>

<script>

export default {
  name: 'App',
  data: () => ({
    loginState : ''
  }),
  mounted() {
    
  },
  components: {

  }
}
</script>

<style lang="scss">

</style>